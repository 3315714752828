import React from "react";
import "./home.css";
import buyImage from "../../assets/Buy1.webp";
import sellImage from "../../assets/Sell1.webp";
import agentImage from "../../assets/find-an-agent.webp";
import lenderImage from "../../assets/busca_lender.webp";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import { Link } from "react-router-dom";

const Home = () => {
  return (
    <div>
      <Header />
      <main>
        {/* HERO */}
        <section>
          <div className="search-container">
            <h3> Próxima parada, casa. </h3>
            <div className="search-button-container">
              <Link to={"/buscar"} className="search-btn-home text-shadow">
                Buscar ahora
              </Link>
            </div>
          </div>
        </section>

        {/* Cards */}
        <section className="home-card-container">
          <Link to={"/buscar"}>
            <div className="home-card">
              <img
                src={buyImage}
                alt="Drawing of a nice modern home with plants in the background and a breeze blowing. Two people are discussing the property outside."
                width={"320px"}
                height={"222px"}
              />
              <div className="home-card-text">
                <h2>Comprar una casa</h2>
                <p>
                  Sus necesidades inmobiliarias son exclusivas para usted.
                  Descubre tu sueño casa con nuestra galería de fotos inmersiva
                  y una amplia gama de listados.
                </p>

                <button>Explorar casas</button>
              </div>
            </div>
          </Link>

          <Link to={"/encuentra-un-agente"}>
            <div className="home-card card-2">
              <img
                src={agentImage}
                alt="Drawing of a nice modern home with plants in the background and a breeze blowing. Two people are discussing the property outside."
                width={"320px"}
                height={"222px"}
              />
              <div className="home-card-text">
                <h2>Encuentra un agente</h2>
                <p>
                  Si está buscando alquilar, comprar o vender su casa, busque un
                  agente local que pueda guiarlo a través del proceso de
                  principio a fin.
                </p>

                <button>Ver agentes</button>
              </div>
            </div>
          </Link>

          <Link to={"/encuentra-un-prestamista"}>
            <div className="home-card card-2">
              <img
                src={lenderImage}
                alt="Drawing of a nice modern home with plants in the background and a breeze blowing. Two people are discussing the property outside."
                width={"320px"}
                height={"222px"}
              />
              <div className="home-card-text">
                <h2>Encuentra un prestamista</h2>
                <p>
                  ¿Listo para comprar una casa nueva o refinanciar su hipoteca?
                  Ponte en contacto con un profesional capacitado que puede
                  apoyarlo en cada paso del el camino.
                </p>

                <button>Ver prestamistas</button>
              </div>
            </div>
          </Link>

          <Link
            to={"https://findahome.hifello.com/lp/654aa58ad4c524002518bb15"}
          >
            <div className="home-card card-2">
              <img
                src={sellImage}
                alt="Drawing of a nice modern home with plants in the background and a breeze blowing. Two people are discussing the property outside."
                width={"320px"}
                height={"222px"}
              />
              <div className="home-card-text">
                <h2>Vender una casa</h2>
                <p>
                  No importa qué enfoque elija para vender su casa, estamos aquí
                  para guiarlo hacia una venta exitosa.
                </p>

                <button>Vea sus opciones</button>
              </div>
            </div>
          </Link>
        </section>
      </main>

      <Footer />
    </div>
  );
};

export default Home;
