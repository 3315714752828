import React from "react";
import { Link } from "react-router-dom";
import footerImgSm from "../../assets/Footer.webp";
import footerImgLg from "../../assets/Footer.webp";
import "./footer.css";

const Footer = () => {
  return (
    <section className="footer-section">
      <div>
        <hr></hr>
      </div>
      <div className="footer">
        <div className="footer-menu">
          <div>
            <h2>Conectar con una profesional</h2>
            <Link
              className="text-shadow-sm decoration-none"
              to={"/encuentra-un-agente"}
            >
              Encuentra Un Agente
            </Link>
            <Link
              className="text-shadow-sm decoration-none"
              to={"/encuentra-un-prestamista"}
            >
              Encuentra Un Prestamista
            </Link>
          </div>

          <div>
            <h2>Recursos Adicionales</h2>
            <a
              className="text-shadow-sm decoration-none"
              href={"https://blog.buscatucasa.com/Propiedas-unicas/"}
            >
              Propiedas Unicas
            </a>
            <a
              className="text-shadow-sm decoration-none"
              href={"https://blog.buscatucasa.com/Education/"}
            >
              Buyer/Seller Education
            </a>
            <Link
              className="text-shadow-sm decoration-none"
              to={"/contactanos"}
            >
              Contactanos
            </Link>
          </div>

          <div>
            <h2>Centro de Servicios Profesionales</h2>
            <Link
              className="text-shadow-sm decoration-none"
              to={"/portal-de-agentes"}
            >
              Inicio de sesión del agente
            </Link>
            <Link
              to={"/agente-inscribirse"}
              className="text-shadow-sm  decoration-none"
            >
              Registro de agente
            </Link>
            <Link
              className="text-shadow-sm decoration-none"
              to={"/inscripción-de-prestamista"}
            >
              Inscripción De Prestamista
            </Link>
          </div>
        </div>

        <img
          className="footer-image"
          alt="A row of inviting houses"
          srcSet={`${footerImgSm} 500w, ${footerImgLg} 700w`}
          sizes="(max-width: 500px) 500px, (max-width: 700px) 700px, 1280px"
          width={"740px"}
          height={"165px"}
          src={footerImgLg}
        />
        <p className="jason text-shadow-sm" style={{ textAlign: "center" }}>
          Jason Galaz, eXp Realty. #344914 p: (888)519-5113
        </p>
        <p className="brain-jar-link">
          Built by&nbsp;
          <a
            className="decoration-none"
            href="https://brainjar.net/"
            target="blank"
          >
            Brain Jar
          </a>
        </p>
      </div>
    </section>
  );
};

export default Footer;
